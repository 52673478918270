import { useQuery } from "@tanstack/react-query";
import { client, ResultObject } from "../common/common";
import { handleErrFormat, handleResFormat } from "../common/common.util";
import { TaskResponse } from "../task/task";

interface Response {
	code: number,//1.1.1,
	name: string,//Professionalization of ICS production,
	target: string,//of professionalised producers,
	actions: TaskResponse[]

}

interface SummaryResponse {
	activity: number,// 24,
	actions: number,// 11,
	tasks: number,// 6,
	reports: number,// 3,
	kpis: any[],// []
	milestones: number
}


interface SummaryParams {
	page?: number | string,
	page_size?: number | string
}

export const useSummaryQuery = (params?: SummaryParams) =>
	useQuery<Response[]>({
		queryKey: ['getSummaryQuery', { ...params }],
		queryFn: () =>
			client.get('/api/summary', { params })
				.then(handleResFormat)
				.catch(handleErrFormat)
	})

export const useDefaultSummary = () =>
	useQuery<ResultObject<SummaryResponse>>({
		queryKey: ['getDashboard'],
		queryFn: () =>
			client.get('/api/summary/home')
				.then(handleResFormat)
				.catch(err => err)
	})