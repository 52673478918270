// import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import { useProfileQuery, UserResponse } from "../../lib/xhr/profile/profile";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../lib/context/auth.context";
import React from "react";
import { AppBar } from "./drawer";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Colors } from "../../config/theme/theme";

const drawerWidth = 240;

export default function Header({
  open,
  handleDrawerOpen,
}: {
  open: boolean;
  handleDrawerOpen: () => void;
}) {
  const profile = useProfileQuery();
  const location = useLocation();
  const auth = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    //TODO check for 401 status
    // if (profile.data?.code === 401) {
    //   alert("you should login");
    //   auth.logout();
    //   return navigate("/auth/login");
    // }
    if (!auth.state.user) {
      // console.log("profile code was ran", profile.data?.code);
      auth.load_user(profile.data?.results as UserResponse);
    }
  }, [profile, auth]);

  return (
    <AppBar
      position="fixed"
      // sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
      open={open}
    >
      <Toolbar sx={{ background: "white", color: "black" }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            background: "#3637407d",
            borderRadius: "1rem",
            "&:hover": { background: Colors.primary.dark },
            color: "white",
          }}
        >
          <MenuIcon />
        </IconButton>
        <Grid container spacing={2}>
          <Grid item xs={7} sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ fontSize: "14px", px: 5 }}
              textTransform="uppercase"
              fontWeight="bold"
            >
              {location.pathname.replace("/", "").replace("-", " ")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            sx={{
              display: "flex",
              gap: 3,
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            {/* <NotificationsRoundedIcon
              fontSize="small"
              sx={{ color: "lightGray" }}
            /> */}
            <Divider orientation="vertical" flexItem />
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ fontSize: "14px" }}
              textTransform="capitalize"
            >
              {profile.data?.results?.name}
            </Typography>
            <Avatar
              sx={{
                height: "2.2rem",
                width: "2.2rem",
              }}
              alt={profile.data?.results?.name}
            >
              <Typography textTransform="uppercase">
                {profile.data?.results?.name?.at(0)}
              </Typography>
            </Avatar>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
